import Phaser from 'phaser';
// import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import TextButton from '../button/TextButton';
import AuctionItem from './AuctionItem';
import configs from '../../configs/configs';

const { width, height } = configs;

const containerWidth = 574;
const containerHeight = 851;
const gap = 40;
const paginationBtnSize = 66;
const paginationBtnGap = 15;

const startX = width / 2 - gap / 2 - containerWidth;
const startY = height * 0.31;

class AuctionItems extends Phaser.GameObjects.Container {
  allAuctionItems = [];
  auctionItems = [];
  items = [];
  paginations = [];
  totalPages = 1;
  page = 0;
  limit = 4;
  mode = 'xGREED';

  constructor(scene, data) {
    super(scene);
    this.scene = scene;

    this.contentContainer = scene.add.container().setSize(width * 0.8, 0);
    this.loadingIcon = scene.add.image(width / 2, height / 2, 'icon-loading-small').setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    if (data) {
      this.loadData(data);
    } else {
      this.showLoading();
      scene.game.events.emit('request-auction-items');
    }

    scene.auctionMode?.switchMode(this.mode);
  }

  refetch() {
    this.clearItems();
    this.scene.game.events.emit('request-auction-items');
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
  }

  loadData(data) {
    this.hideLoading();
    this.allAuctionItems = data;
    this.auctionItems = data.filter((item) => item.bidCurrency === this.mode);
    this.totalPages = Math.ceil(this.auctionItems.length / this.limit);
    this.page = 0;
    this.updatePagination();
    this.renderList();
  }

  showMode(mode, page = undefined) {
    this.mode = mode;
    this.auctionItems = this.allAuctionItems.filter((item) => item.bidCurrency === this.mode);
    this.totalPages = Math.ceil(this.auctionItems.length / this.limit);
    this.page = page || 0;
    this.updatePagination();
    this.renderList();
  }

  changePage(newPage) {
    if (this.loading) return;
    if (newPage === undefined || newPage === null) return;
    if (newPage < 0 || newPage > this.totalPages - 1) return;
    if (this.page === newPage) return;

    this.page = newPage;
    this.updatePagination();
    this.renderList();
  }

  updatePagination() {
    const pageBtns = [{ text: '1', page: 0 }];

    if (this.totalPages <= 5) {
      let count = 1;
      while (count < this.totalPages) {
        pageBtns.push({ text: `${count + 1}`, page: count });
        count++;
      }
    } else {
      if ([0, 1, this.totalPages - 2, this.totalPages - 1].includes(this.page)) {
        pageBtns.push(
          ...[
            { text: '2', page: 1 },
            { text: '...' },
            { text: `${this.totalPages - 1}`, page: this.totalPages - 2 },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      } else {
        pageBtns.push(
          ...[
            { text: '...' },
            { text: `${this.page + 1}`, page: this.page },
            { text: '...' },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      }
    }

    this.paginations.map((item) => {
      this.remove(item);
      item.destroy();
    });

    const canBack = this.page > 0;
    const canNext = this.page < this.totalPages - 1;

    const allPageBtns = [
      {
        text: '<',
        page: this.page - 1,
        color: canBack ? '#C4CDD5' : '#f2f2f2',
        img: canBack ? 'pagination' : 'pagination-disabled',
      },
      ...pageBtns.map((item) => ({
        ...item,
        color: this.page === item.page ? '#7C2828' : '#000000',
        img: this.page === item.page ? 'pagination-active' : 'pagination',
      })),
      {
        text: '>',
        page: this.page + 1,
        color: canNext ? '#C4CDD5' : '#f2f2f2',
        img: canNext ? 'pagination' : 'pagination-disabled',
      },
    ];

    const paginationY = startY + 2 * containerHeight + 3 * gap - 10;
    const paginationWidth = allPageBtns.length * paginationBtnSize + (allPageBtns.length - 1) * paginationBtnGap;
    this.paginations = allPageBtns.map((item, index) => {
      const x =
        width / 2 - paginationWidth / 2 + index * (paginationBtnSize + paginationBtnGap) + paginationBtnSize / 2;
      const btn = new TextButton(
        this.scene,
        x,
        paginationY,
        item.img,
        item.img,
        () => this.changePage(item.page),
        item.text,
        {
          fontSize: '31px',
          color: item.color,
        }
      );
      this.add(btn);
      return btn;
    });
  }

  clearItems() {
    // clear old rendered items
    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroyAll();
      // item.destroy();
    });
  }

  renderList() {
    this.items.map((item) => {
      item.destroyAll();
    });

    this.items = [];

    const renderedItems = this.auctionItems.slice(this.page * this.limit, (this.page + 1) * this.limit);
    for (let i = 0; i < renderedItems.length; i++) {
      const auctionItem = renderedItems[i];
      const x = i % 2 === 0 ? startX : startX + containerWidth + gap;
      const y = startY + Math.floor(i / 2) * (containerHeight + gap);
      const item = new AuctionItem(this.scene, { x, y, item: auctionItem });
      this.items.push(item);
      this.add(item);
    }
  }
}

export default AuctionItems;
