import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import { fontFamilies } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width } = configs;

const columnStyle = {
  fontSize: '36px',
  fontFamily: fontFamilies.bold,
  color: '#7C2828',
  align: 'center',
};

const itemStyle = {
  fontSize: '36px',
  fontFamily: fontFamilies.bold,
  color: '#29000B',
};

const rowHeight = 99;
const paginationBtnSize = 66;
const paginationBtnGap = 15;

class PopupAuctionItemHistory extends Popup {
  listY = this.popup.y - this.popup.height / 2 + 190;
  paginations = [];
  page = 0;
  limit = 20;
  totalPages = 1;
  items = [];
  itemHistoryData = [];
  subId = null;
  title = '';
  bidCurrency = '';

  constructor(scene) {
    super(scene, 'popup-medium', {
      title: 'Bidding History',
    });
    this.scene = scene;

    this.closeBtn = new TextButton(
      scene,
      this.popup.x,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => this.forceClose(),
      'Close',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.closeBtn);

    const columnY = this.listY - 40;

    this.dateText = scene.add
      .text(this.popup.x - this.popup.width / 2 + 80, columnY, 'Date', columnStyle)
      .setOrigin(0, 0.5);
    this.minWinText = scene.add.text(this.popup.x - 220, columnY, 'Min Win', columnStyle).setOrigin(0.5, 0.5);
    this.numberOfBiddingsText = scene.add.text(this.popup.x, columnY, 'Total # Bid', columnStyle).setOrigin(0.5, 0.5);
    this.totalAmountBidText = scene.add
      .text(this.popup.x + 265, columnY, 'Total Amt Bid', columnStyle)
      .setOrigin(0.5, 0.5);

    this.add(this.dateText);
    this.add(this.minWinText);
    this.add(this.numberOfBiddingsText);
    this.add(this.totalAmountBidText);

    this.listContainer = scene.add.image(this.popup.x, this.listY, 'container-bidding-history').setOrigin(0.5, 0);
    this.add(this.listContainer);
    this.contentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.loadingIcon = scene.add.image(this.popup.x, this.popup.y, 'icon-loading-small').setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
  }

  loadData(itemHistoryData) {
    this.hideLoading();
    this.bidCurrency = itemHistoryData.bidCurrency;
    this.itemHistoryData = itemHistoryData.items;
    this.totalPages = Math.ceil(itemHistoryData.items.length / this.limit);
    this.page = 0;
    if (!this.visible) return;
    this.updatePagination();
    this.updateList();
    this.loading = false;
  }

  reloadData() {
    if (this.loading) return;
    this.updateList();
  }

  changePage(newPage) {
    if (this.loading) return;
    if (newPage === undefined || newPage === null) return;
    if (newPage < 0 || newPage > this.totalPages - 1) return;
    if (this.page === newPage) return;

    this.page = newPage;
    this.updatePagination();
    this.reloadData();
  }

  hideItems() {
    this.items.map((item) => {
      item.setVisible(false);
    });
  }

  clearItems() {
    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroy();
    });

    this.items = [];
  }

  updateList() {
    this.clearItems();

    if (!this.itemHistoryData || !this.itemHistoryData.length || !this.visible) return;

    const itemHistoryData = this.itemHistoryData.slice(this.page * this.limit, (this.page + 1) * this.limit);

    for (let i = 0; i < itemHistoryData.length; i++) {
      const y = i * rowHeight;
      const { id, date, minWin, numberOfBiddings, totalBiddingValue } = itemHistoryData[i];

      if (i % 2 === 1) {
        const bg = this.scene.add
          .image(this.popup.width / 2 - 90, y + rowHeight / 2, 'row-container-99')
          .setOrigin(0.5, 0.5);
        this.items.push(bg);
      }

      const timeText = this.scene.add
        .text(this.popup.width * 0.02, y + rowHeight / 2, date, itemStyle)
        .setOrigin(0, 0.5);

      const minWinText = this.scene.add
        .text(this.popup.width * 0.27, y + rowHeight / 2, `${formatter.format(minWin)}`, itemStyle)
        .setOrigin(1, 0.5);

      const coinIcon = this.bidCurrency === 'xGREED' ? 'xgreed-tiny' : this.bidCurrency === 'POOR' ? 'poor-tiny' : '';
      const coin = this.scene.add.image(minWinText.x + 10, y + rowHeight / 2, coinIcon).setOrigin(0, 0.5);

      const numberOfBiddingsText = this.scene.add
        .text(this.popup.width * 0.45, y + rowHeight / 2, `${numberOfBiddings}`, itemStyle)
        .setOrigin(0.5, 0.5);

      const totalBiddingValueText = this.scene.add
        .text(this.popup.width * 0.67, y + rowHeight / 2, `${customFormat(totalBiddingValue, 3)}`, itemStyle)
        .setOrigin(1, 0.5);

      const xCoin = this.scene.add.image(totalBiddingValueText.x + 10, y + rowHeight / 2, coinIcon).setOrigin(0, 0.5);

      const searchIcon = this.scene.add.image(this.popup.width * 0.848, y + rowHeight / 2, 'search').setOrigin(1, 0.5);

      const scene = this.scene;
      searchIcon.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        this.close();
        scene.popupAuctionItemHistoryDetail?.show({ subId: this.subId, recordId: id, title: this.title });
      });

      this.items.push(timeText, minWinText, coin, numberOfBiddingsText, totalBiddingValueText, xCoin, searchIcon);
    }
    this.contentContainer.add(this.items);

    const contentContainerHeight = itemHistoryData.length * rowHeight;
    this.contentContainer.setSize(0, contentContainerHeight);
    if (this.table) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
      this.remove(this.table);
      this.table.destroy(true);
      this.table = null;
    }

    if (this.thumb) {
      this.remove(this.thumb);
      this.thumb.destroy(true);
    }

    const tableHeight = this.listContainer.height;
    const visibleRatio = tableHeight / contentContainerHeight;
    this.thumb = this.scene.rexUI.add
      .roundRectangle({
        height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.table = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.listY + tableHeight / 2,
      width: this.listContainer.width,
      height: tableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.contentContainer, mask: { padding: 1 } },
      slider: { thumb: this.thumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (itemHistoryData.length <= 9 || !this.visible) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
    } else {
      this.table.setScrollerEnable(true);
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.add(this.table);
    // if (!this.visible) {
    //   this.table.setScrollerEnable(false);
    //   this.table.setMouseWheelScrollerEnable(false);
    // }

    this.table.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.thumb.visible) return;
      this.thumb.setVisible(true);
    });
  }

  updatePagination() {
    const pageBtns = [{ text: '1', page: 0 }];

    if (this.totalPages <= 5) {
      let count = 1;
      while (count < this.totalPages) {
        pageBtns.push({ text: `${count + 1}`, page: count });
        count++;
      }
    } else {
      if ([0, 1, this.totalPages - 2, this.totalPages - 1].includes(this.page)) {
        pageBtns.push(
          ...[
            { text: '2', page: 1 },
            { text: '...' },
            { text: `${this.totalPages - 1}`, page: this.totalPages - 2 },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      } else {
        pageBtns.push(
          ...[
            { text: '...' },
            { text: `${this.page + 1}`, page: this.page },
            { text: '...' },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      }
    }

    this.paginations.map((item) => {
      this.remove(item);
      item.destroy();
    });

    const canBack = this.page > 0;
    const canNext = this.page < this.totalPages - 1;

    const allPageBtns = [
      {
        text: '<',
        page: this.page - 1,
        color: canBack ? '#C4CDD5' : '#f2f2f2',
        img: canBack ? 'pagination' : 'pagination-disabled',
      },
      ...pageBtns.map((item) => ({
        ...item,
        color: this.page === item.page ? '#7C2828' : '#000000',
        img: this.page === item.page ? 'pagination-active' : 'pagination',
      })),
      {
        text: '>',
        page: this.page + 1,
        color: canNext ? '#C4CDD5' : '#f2f2f2',
        img: canNext ? 'pagination' : 'pagination-disabled',
      },
    ];

    const paginationY = this.listY + this.listContainer.height + 70;
    const paginationWidth = allPageBtns.length * paginationBtnSize + (allPageBtns.length - 1) * paginationBtnGap;
    this.paginations = allPageBtns.map((item, index) => {
      const x =
        width / 2 - paginationWidth / 2 + index * (paginationBtnSize + paginationBtnGap) + paginationBtnSize / 2;
      const btn = new TextButton(
        this.scene,
        x,
        paginationY,
        item.img,
        item.img,
        () => this.changePage(item.page),
        item.text,
        {
          fontSize: '31px',
          color: item.color,
        }
      );
      this.add(btn);
      return btn;
    });
  }

  show({ subId, title }) {
    this.subId = subId;
    this.title = title;
    this.setTitle(title);
    this.hideItems();
    this.showLoading();
    this.loading = true;

    this.open();
    this.scene.game.events.emit('request-auction-item-bidding-history', { subId });
  }

  onClickClose() {
    this.forceClose();
  }

  cleanup() {
    this.scene?.refreshItems?.();
  }
}

export default PopupAuctionItemHistory;
