const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;

const width = windowWidth / windowHeight >= 1700 / 2796 ? 1700 : (windowWidth * 2796) / windowHeight;

const configs = {
  width: width,
  height: 2796,
  gangsterAnimation: {
    back: {
      start: { x: 150, y: 2000, scale: 0.4 },
      end: { x: width / 2 - 150, y: 880, scale: 0.15 },
      time: 3000,
    },
    front: {
      start: { x: width / 2 - 150, y: 880, scale: 0.15 },
      end: {
        x: width / 2 - 150,
        y: 2100,
        scale: 0.4,
      },
      time: 3000,
    },
  },
  goonAnimation: {
    back: {
      start: { x: width - 150, y: 2000, scale: 0.4 },
      end: { x: width / 2 + 150, y: 880, scale: 0.15 },
      time: 3000,
    },
    front: {
      start: { x: width / 2 + 150, y: 880, scale: 0.15 },
      end: {
        x: width / 2 + 150,
        y: 2100,
        scale: 0.4,
      },
      time: 3000,
    },
  },
  thugAnimation: {
    back: {
      start: { x: width / 2, y: 2100, scale: 0.4 },
      end: { x: width / 2, y: 880, scale: 0.15 },
      time: 3000,
    },
    front: {
      start: { x: width / 2, y: 880, scale: 0.15 },
      end: {
        x: width / 2,
        y: 2100,
        scale: 0.4,
      },
      time: 3000,
    },
  },
};
export default configs;
