import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import { updatePassCode } from '../../services/user.service';

const PassCode = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [passCode, setPassCode] = useState('');

  const submit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await updatePassCode({ passCode });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        width="100vw"
        minHeight="100vh"
        position="absolute"
        sx={{
          zIndex: -1,
          top: 0,
          backgroundImage: {
            xs: 'url(images/bg-login-vertical.webp)',
            sm: 'url(images/bg-login-5x4.webp)',
            md: 'url(images/bg-login.webp)',
          },
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <Box
        minHeight="100vh"
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bgcolor="rgba(0, 0, 0, 0.1)">
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center" gap={3}>
          <Box
            flex={1}
            mx="auto"
            width={{ xs: '100%', sm: '400px' }}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            gap={2}>
            <Box id="twitter-link-container">
              <Box p={2} bgcolor="#fbf3e7" display="flex" flexDirection="column" gap={3}>
                <Box position="relative">
                  <Typography fontWeight={600} align="center">
                    Pass Code
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img src="/images/logo.svg" width="60%" alt="logo" />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" gap={1.2}>
                  <Typography fontWeight={600} align="center" color="error">
                    Fill in pass code to enter playtest
                  </Typography>
                  <Box
                    width="100%"
                    sx={{
                      '& input': {
                        display: 'block',
                        width: '100%',
                        borderRadius: 2,
                        border: '1px solid black',
                        outline: 'none',
                        px: 2,
                        py: 1,
                        textAlign: 'center',
                        fontSize: 32,
                        fontWeight: 500,
                      },
                    }}>
                    <input value={passCode} onChange={(e) => setPassCode(e.target.value)} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" mt={2} gap={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={!passCode.trim()}
                    onClick={submit}
                    sx={{
                      borderRadius: '4%/24%',
                      backgroundColor: 'black',
                      backgroundImage: 'url(/images/button-black.png)',
                      backgroundSize: '100% 100%',
                      backgroundRepeat: 'no-repeat',
                      aspectRatio: 5.62 / 1,
                      boxShadow: 'none',
                      transition: 'all ease 0.3s',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: 'black',
                        backgroundImage: 'url(/images/button-black-pressed.png)',
                      },
                      '&:disabled': {
                        opacity: 0.7,
                      },
                    }}>
                    <Box
                      width="100%"
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}>
                      <Typography align="center" fontSize={20} fontWeight={800} sx={{ textTransform: 'none' }}>
                        {loading ? 'Loading' : 'Continue'}
                      </Typography>
                      {passCode.trim() && (
                        <img
                          src="/images/next-arrow.png"
                          alt="next"
                          width="5%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            transform: 'translateY(-50%)',
                          }}
                        />
                      )}
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PassCode;
