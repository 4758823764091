import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import TextInput from '../inputs/TextInput';
import { formatter } from '../../../../utils/numbers';
import { formatUsername } from '../../../../utils/strings';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import configs from '../../configs/configs';

const { width, height } = configs;

const rowHeight = 162;
const paginationBtnSize = 66;
const paginationBtnGap = 15;
const smallBlackBoldCenter = {
  fontSize: fontSizes.small,
  color: colors.black,
  fontFamily: fontFamilies.bold,
  align: 'center',
};

const MAX_USERNAME_LENGTH = 9;

const options = {
  rank: 'Reputation Rank',
  lastDefend: 'Last Defence',
  lastDayTokenReward: 'Last Earnings',
  raidPoint: 'Raid Points',
};

const dropdownOptionLineGap = 100;
class PopupWarAttack extends Popup {
  uid = null;
  loading = false;
  page = 0;
  limit = 50;
  search = '';
  orderBy = 'rank';
  sortDirection = 'asc';
  totalPages = 10;
  users = [];
  listY = height / 2 - 420;
  items = [];
  paginations = [];
  networth = 0;
  stolenNetworthPercent = 0;

  constructor(scene) {
    super(scene, 'popup-war-attack', { title: 'Raid' });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        scene.popupWarMachines?.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.backBtn);

    this.timeText = scene.add.text(width / 2 + 100, this.popup.y + this.popup.height / 2 - 240, '0h 00m', {
      fontSize: '50px',
      color: '#29000B',
      fontFamily: fontFamilies.extraBold,
    });
    this.add(this.timeText);

    this.searchInput = new TextInput(scene, width / 2, height / 2 - this.popup.height / 2 + 200, {
      inputImg: 'search-input',
      icon: 'icon-search',
      iconHorizontalPadding: 10,
      iconRightMargin: 70,
      placeholder: 'Search user',
      fontSize: '54px',
      color: '#29000B',
      onChange: (value) => {
        this.search = value;
      },
      onBlur: () => this.reloadData(),
    });
    this.add(this.searchInput);

    this.dropdownY = height / 2 - this.popup.height / 2 + 380;
    this.dropdownBtn = scene.add.image(width / 2, this.dropdownY, 'dropdown-select');
    this.dropdownTitle = scene.add
      .text(width / 2 - this.popup.width * 0.25, this.dropdownY, options.rank, {
        fontSize: '40px',
        color: colors.brown,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, 0.5);
    this.dropdownArrow = scene.add.image(width / 2 + this.popup.width * 0.22, this.dropdownY, 'icon-arrow-dropdown');
    this.dropdownBtn.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => this.toggleDropdown());
    this.add(this.dropdownBtn);
    this.add(this.dropdownTitle);
    this.add(this.dropdownArrow);

    this.sortButton = scene.add.image(width / 2 + this.popup.width * 0.37, this.dropdownY, 'button-sort-asc');
    this.sortButton.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      // close the dropdown
      const isOpen = Boolean(this.dropdownArrow.rotation);
      if (isOpen) this.toggleDropdown();

      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      this.sortButton.setTexture(`button-sort-${this.sortDirection}`);
      this.reloadData();
    });
    this.add(this.sortButton);

    this.listContainer = scene.add.image(width / 2, this.listY, 'container-large-3').setOrigin(0.5, 0);
    this.add(this.listContainer);
    this.contentContainer = scene.add.container().setSize(this.popup.width * 0.8, 0);

    this.popup.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
      const isOpen = Boolean(this.dropdownArrow.rotation);
      if (isOpen) this.toggleDropdown();
    });

    this.loadingIcon = scene.add
      .image(this.popup.x, this.listContainer.y + this.listContainer.height / 2, 'icon-loading-small')
      .setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    scene.game.events.on('update-user-list-to-attack', ({ totalPages, users }) => {
      this.totalPages = totalPages;
      this.users = users;
      this.updateList();
      this.updatePagination();
      this.setLoading(false);
    });

    scene.game.events.on('update-networth', ({ networth }) => {
      if (networth === this.networth) return;
      this.networth = networth;
      this.updateList();
    });
    scene.game.events.on('update-war-config', ({ stolenNetworthPercent }) => {
      this.stolenNetworthPercent = stolenNetworthPercent;
      this.updateList();
    });

    scene.game.events.on('update-next-war-time', ({ time }) => {
      const now = Date.now();
      const diffInMins = (time - now) / (60 * 1000);
      const hours = Math.floor(diffInMins / 60);
      const mins = Math.floor(diffInMins % 60);

      const timeText = `${hours}h ${mins.toString().padStart(2, '0')}m`;
      this.timeText.text = timeText;
    });

    scene.game.events.on('update-auth', ({ uid }) => {
      this.uid = uid;
      this.reloadData();
    });
    scene.game.events.on('update-user-list-to-attack-failed', () => {
      this.setLoading(false);
    });

    scene.game.events.emit('request-next-war-time');
    scene.game.events.emit('request-auth');
    scene.game.events.emit('request-networth');
    scene.game.events.emit('request-war-config');
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
    this.items.forEach((item) => item.setAlpha(0.5));
  }

  hideLoading() {
    this.loadingAnimation.pause();
    this.loadingIcon.setVisible(false);
    this.items.forEach((item) => item.setAlpha(1));
  }

  onOpen() {
    if (this.table) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
    }
    this.scene.game.events.emit('request-next-war-time');
    if (this.uid) {
      this.page = 0;
      this.search = '';
      this.searchInput?.updateValue('');
      this.reloadData();
    }
  }

  backFromWarDetail() {
    this.setVisible(true);
    if (this.table) {
      this.table.setScrollerEnable(true);
      this.table.setMouseWheelScrollerEnable(true);
    }
  }

  cleanup() {
    if (this.table) {
      // this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
      this.thumb?.setVisible(false);
    }

    // close the dropdown
    const isOpen = Boolean(this.dropdownArrow.rotation);
    if (isOpen) this.toggleDropdown();
  }

  selectDropdownOption(option) {
    const isOpen = Boolean(this.dropdownArrow.rotation);
    if (!isOpen) return;

    this.orderBy = option;
    this.dropdownTitle.text = options[option];

    this.reloadData();
    this.toggleDropdown();
  }

  toggleDropdown() {
    const isOpen = Boolean(this.dropdownArrow.rotation);
    this.dropdownArrow.setRotation(isOpen ? 0 : Math.PI);

    if (!isOpen) {
      this.dropdownContainer = this.scene.add.container().setSize(200, 0);
      this.dropdownContainer.setDepth(20);
      this.add(this.dropdownContainer);
      this.dropdownOptionsBg = this.scene.add
        .image(width / 2, this.dropdownY + this.dropdownBtn.height / 2, 'dropdown-options-container')
        .setOrigin(0.5, 0);

      this.dropdownContainer.add(this.dropdownOptionsBg);

      for (let [index, optionKey] of Object.keys(options).entries()) {
        this[`dropdownOption${optionKey}`] = this.scene.add
          .image(
            width / 2,
            this.dropdownY + this.dropdownBtn.height / 2 + 20 + dropdownOptionLineGap * index,
            'dropdown-option-bg'
          )
          .setOrigin(0.5, 0);
        this[`dropdownTitle${optionKey}`] = this.scene.add
          .text(
            width / 2 - this.popup.width * 0.2,
            this.dropdownY + this.dropdownBtn.height / 2 + 20 + 50 + dropdownOptionLineGap * index,
            options[optionKey],
            {
              fontSize: '36px',
              color: colors.brown,
              fontFamily: fontFamilies.bold,
            }
          )
          .setOrigin(0, 0.5);

        this.dropdownContainer.add(this[`dropdownOption${optionKey}`]);
        this.dropdownContainer.add(this[`dropdownTitle${optionKey}`]);

        this[`dropdownOption${optionKey}`]
          .setInteractive()
          .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => this.selectDropdownOption(optionKey));
      }
    } else if (this.dropdownContainer) this.dropdownContainer.destroy();
  }

  reloadData() {
    if (!this.visible || this.loading) return;
    this.setLoading(true);
    this.scene.game.events.emit('request-user-list-to-attack', {
      page: this.page,
      limit: this.limit,
      search: this.search,
      orderBy: this.orderBy,
      sortDirection: this.sortDirection,
    });
  }

  changePage(newPage) {
    if (this.loading) return;
    if (newPage === undefined || newPage === null) return;
    if (newPage < 0 || newPage > this.totalPages - 1) return;
    if (this.page === newPage) return;

    this.page = newPage;
    this.updatePagination();
    this.reloadData();
  }

  setLoading(status) {
    if (status) this.showLoading();
    else this.hideLoading();
    this.loading = status;
    this.searchInput.setDisabled(status);
  }

  updateList() {
    // if (!this.users.length) return;

    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroy();
    });

    this.items = [];
    const usernameX = this.popup.width * 0.1;
    const lastDayTokenX = this.popup.width * 0.43;
    const lastDefendX = this.popup.width * 0.59;
    for (let i = 0; i < this.users.length; i++) {
      const { userId, rank, username, networth, lastDayTokenReward, lastDefend, active, avatarURL } = this.users[i];
      const y = i * rowHeight;
      const firstLineY = userId === this.uid ? y + rowHeight * 0.5 : y + rowHeight * 0.32;
      const secondLineY = y + rowHeight * 0.7;
      if (i % 2 === 1) {
        const bg = this.scene.add.image(this.popup.width / 2 - 90, y, 'row-container-162').setOrigin(0.5, 0);
        this.items.push(bg);
      }
      const rankText = this.scene.add
        .text(this.popup.width * 0.05, firstLineY, `${rank}`, smallBlackBoldCenter)
        .setOrigin(0.5, 0.5);
      const usernameText = this.scene.add
        .text(usernameX, firstLineY, formatUsername({ username, MAX_USERNAME_LENGTH }), smallBlackBoldCenter)
        .setOrigin(0, 0.5);
      const usernameUnderline = this.scene.add
        .rectangle(usernameX, firstLineY + 20, usernameText.width, 2, 0x29000b)
        .setOrigin(0, 0.5);

      usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        this.loading = false;
        this.close();
        this.scene.popupWarAttackDetail?.viewUser({ userId, onBack: () => this.backFromWarDetail() });
      });

      const lastDayTokenRewardText = this.scene.add
        .text(
          lastDayTokenX,
          firstLineY,
          `+${formatter.format((lastDayTokenReward || 0).toPrecision(3))}`,
          smallBlackBoldCenter
        )
        .setOrigin(1, 0.5);
      const lastDayTokenIcon = this.scene.add
        .image(lastDayTokenX + 5, firstLineY, 'icon-xgang-small')
        .setOrigin(0, 0.5)
        .setDisplaySize(60, 60);
      const lastDefendText = this.scene.add
        .text(lastDefendX, firstLineY, `+${formatter.format((lastDefend || 0).toPrecision(3))}`, smallBlackBoldCenter)
        .setOrigin(1, 0.5);
      const lastDefendIcon = this.scene.add.image(lastDefendX + 10, firstLineY, 'icon-defend').setOrigin(0, 0.5);

      this.items.push(
        rankText,
        usernameText,
        usernameUnderline,
        lastDayTokenRewardText,
        lastDayTokenIcon,
        lastDefendText,
        lastDefendIcon
      );

      if (userId !== this.uid && active) {
        // const reputationBase = Math.min(this.networth, networth);
        // const networthChange = reputationBase * this.stolenNetworthPercent;
        const reputationText = this.scene.add
          .text(usernameX + 55, secondLineY, `${formatter.format(Math.floor(networth))}`, {
            ...smallBlackBoldCenter,
            color: colors.brown,
          })
          .setOrigin(0, 0.5);
        const reputationIcon = this.scene.add.image(usernameX + 25, secondLineY, 'icon-star');

        const attackBtn = new TextButton(
          this.scene,
          this.popup.width * 0.6 + 200,
          firstLineY,
          'button-blue-small',
          'button-blue-small',
          () => {
            this.loading = false;
            this.scene.popupWarMachines?.addTarget({ userId, username, avatarURL });
            this.close();
            this.scene.popupWarMachines?.open();
          },
          'Raid',
          { fontSize: '36px' }
        );

        this.items.push(reputationText, reputationIcon, attackBtn);
      }
    }
    this.contentContainer.add(this.items);

    const contentContainerHeight = this.users.length * rowHeight;
    this.contentContainer.setSize(0, contentContainerHeight);
    if (this.table) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
      this.remove(this.table);
      this.table.destroy(true);
      this.table = null;
    }

    if (this.thumb) {
      this.remove(this.thumb);
      this.thumb.destroy(true);
    }

    const tableHeight = this.listContainer.height;
    const visibleRatio = tableHeight / contentContainerHeight;
    this.thumb = this.scene.rexUI.add
      .roundRectangle({
        height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.table = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.listY + tableHeight / 2,
      width: this.listContainer.width,
      height: tableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.contentContainer, mask: { padding: 1 } },
      slider: { thumb: this.thumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (this.users.length <= 6) {
      this.table.setScrollerEnable(false);
      this.table.setMouseWheelScrollerEnable(false);
    } else {
      this.table.setScrollerEnable(true);
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.add(this.table);
    // if (!this.visible) {
    //   this.table.setScrollerEnable(false);
    //   this.table.setMouseWheelScrollerEnable(false);
    // }

    this.table.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.thumb.visible) return;
      this.thumb.setVisible(true);
    });

    // redraw the dropdown
    const isOpen = Boolean(this.dropdownArrow.rotation);
    if (isOpen) {
      this.toggleDropdown();
      this.toggleDropdown();
    }
  }

  updatePagination() {
    const pageBtns = [{ text: '1', page: 0 }];

    if (this.totalPages <= 5) {
      let count = 1;
      while (count < this.totalPages) {
        pageBtns.push({ text: `${count + 1}`, page: count });
        count++;
      }
    } else {
      if ([0, 1, this.totalPages - 2, this.totalPages - 1].includes(this.page)) {
        pageBtns.push(
          ...[
            { text: '2', page: 1 },
            { text: '...' },
            { text: `${this.totalPages - 1}`, page: this.totalPages - 2 },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      } else {
        pageBtns.push(
          ...[
            { text: '...' },
            { text: `${this.page + 1}`, page: this.page },
            { text: '...' },
            { text: `${this.totalPages}`, page: this.totalPages - 1 },
          ]
        );
      }
    }

    this.paginations.map((item) => {
      this.remove(item);
      item.destroy();
    });

    const canBack = this.page > 0;
    const canNext = this.page < this.totalPages - 1;

    const allPageBtns = [
      {
        text: '<',
        page: this.page - 1,
        color: canBack ? '#C4CDD5' : '#f2f2f2',
        img: canBack ? 'pagination' : 'pagination-disabled',
      },
      ...pageBtns.map((item) => ({
        ...item,
        color: this.page === item.page ? '#7C2828' : '#000000',
        img: this.page === item.page ? 'pagination-active' : 'pagination',
      })),
      {
        text: '>',
        page: this.page + 1,
        color: canNext ? '#C4CDD5' : '#f2f2f2',
        img: canNext ? 'pagination' : 'pagination-disabled',
      },
    ];

    const paginationY = this.listY + this.listContainer.height + 80;
    const paginationWidth = allPageBtns.length * paginationBtnSize + (allPageBtns.length - 1) * paginationBtnGap;
    this.paginations = allPageBtns.map((item, index) => {
      const x =
        width / 2 - paginationWidth / 2 + index * (paginationBtnSize + paginationBtnGap) + paginationBtnSize / 2;
      const btn = new TextButton(
        this.scene,
        x,
        paginationY,
        item.img,
        item.img,
        () => this.changePage(item.page),
        item.text,
        {
          fontSize: '31px',
          color: item.color,
        }
      );
      this.add(btn);
      return btn;
    });
  }
}

export default PopupWarAttack;
