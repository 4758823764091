import Phaser from 'phaser';

import Popup from './Popup';
import PopupConfirmWarUpgrades from './PopupConfirmWarUpgrades';
import PopupProcessing from './PopupProcessing';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { customFormat, formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { fibonacis, maxStepCount, maxStepSizeIndex } from '../../../../utils/constants';

const { width, height } = configs;
const DEFAULT_QUANTITY = 0;
const INTERVAL = 100;
const counterBtnOffset = 150;
const largeBlackExtraBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.extraBold,
};
const smallBrownBold = { fontSize: fontSizes.small, color: colors.brown, fontFamily: fontFamilies.bold };

class PopupWarUpgrades extends Popup {
  gas = 0;
  numberOfPistols = 0;
  numberOfShields = 0;
  balance = 0;
  xTokenBalance = 0;
  pistolPrice = 0;
  pistolMaxPerBatch = 100;
  shieldPrice = 0;
  shieldMaxPerBatch = 100;
  pistolQuantity = DEFAULT_QUANTITY;
  shieldQuantity = DEFAULT_QUANTITY;
  isSimulator = false;
  purchaseToken = 'GREED'; // 'xGREED' || 'GREED'
  stepSizeIndex = 0;
  stepCount = 0;
  referralDiscount = 0;

  constructor(scene, { isSimulator, ...configs } = {}) {
    super(scene, 'popup-war-upgrades', { title: 'Upgrades', ...configs });

    this.scene = scene;
    this.isSimulator = isSimulator;
    const leftContentX = this.popup.x - this.popup.width * 0.23;
    const rightContentX = this.popup.x + this.popup.width * 0.23;
    const numberOfPistolsY = this.popup.y - this.popup.height / 2 + 690;
    const checkBoxY = numberOfPistolsY + 490;
    const counterY = numberOfPistolsY + 240;
    const leftCheckBoxX = this.popup.x - this.popup.width / 2 + 160;
    const rightCheckBoxX = this.popup.x + 72;
    const availableY = checkBoxY + 50;
    const priceY = availableY + 180;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'spin-result-sound',
      completedEvent: 'war-upgrade-completed',
      completedIcon: 'icon-war-upgrade-done',
      failedIcon: 'icon-war-upgrade',
      description: ``,
    });
    scene.add.existing(this.popupBuyProcessing);
    this.popupConfirm = new PopupConfirmWarUpgrades(scene, this, {
      icon2: 'icon-coin-small',
      onConfirm: () => {
        if (!this.pistolQuantity && !this.shieldQuantity) return;
        this.popupBuyProcessing.initLoading(`Purchasing upgrades.\nPlease, wait`);

        scene.game.events.emit('war-upgrade-start', {
          pistolQuantity: this.pistolQuantity,
          shieldQuantity: this.shieldQuantity,
          token: this.purchaseToken,
        });
      },
    });
    scene.add.existing(this.popupConfirm);

    this.cancelBtn = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Cancel',
      { fontSize: '82px', sound: 'buy', disabledImage: 'button-disabled' }
    );
    this.add(this.cancelBtn);

    this.upgradeBtn = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.close();
        this.popupConfirm.open();
      },
      'Confirm',
      { fontSize: '82px', sound: 'buy', disabledImage: 'button-disabled' }
    );
    this.add(this.upgradeBtn);
    this.upgradeBtn.setDisabledState(!this.isSimulator);

    this.numberOfPistolsText = scene.add
      .text(leftContentX, numberOfPistolsY, 'Pistol Upgrades: 0', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfPistolsText);

    this.numberOfShieldsText = scene.add
      .text(rightContentX, numberOfPistolsY, 'Shield Upgrades: 0', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfShieldsText);

    this.xgangUnchecked = scene.add.image(leftCheckBoxX, checkBoxY, 'icon-checkbox-false');
    this.xgangChecked = scene.add.image(leftCheckBoxX, checkBoxY, 'icon-checkbox-true').setVisible(false);
    this.tokenUnchecked = scene.add.image(rightCheckBoxX, checkBoxY, 'icon-checkbox-false');
    this.tokenChecked = scene.add.image(rightCheckBoxX, checkBoxY, 'icon-checkbox-true');
    this.add(this.xgangUnchecked);
    this.add(this.xgangChecked);
    this.add(this.tokenUnchecked);
    this.add(this.tokenChecked);

    this.xgangUnchecked.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.xgangChecked.setVisible(true);
      this.tokenChecked.setVisible(false);
      this.purchaseToken = 'xGREED';
      this.popupConfirm.updateIconRight('icon-xgang-small');
      this.insufficientBalance.text = 'Insufficient xGREED';
      if (this.coin) this.coin.setTexture('icon-xgang-small');
      this.updateValues();
    });
    this.tokenUnchecked.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      this.xgangChecked.setVisible(false);
      this.tokenChecked.setVisible(true);
      this.purchaseToken = 'GREED';
      this.popupConfirm.updateIconRight('icon-coin-small');
      this.insufficientBalance.text = 'Insufficient $GREED';
      if (this.coin) this.coin.setTexture('icon-coin-small');
      this.updateValues();
    });

    this.xgangAvailable = scene.add.text(leftCheckBoxX + 140, availableY, 'Available: 0', smallBrownBold);
    this.tokenAvailable = scene.add.text(rightCheckBoxX + 140, availableY, 'Available: 0', smallBrownBold);
    this.add(this.xgangAvailable);
    this.add(this.tokenAvailable);

    this.pistolMinusBtn = new TextButton(
      scene,
      leftContentX - counterBtnOffset,
      counterY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.pistolQuantity > DEFAULT_QUANTITY) {
          this.pistolQuantity--;
          this.updateValues();
        }
      },
      '-',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.pistolQuantity > DEFAULT_QUANTITY) {
              this.pistolQuantity = Math.max(0, this.pistolQuantity - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.pistolMinusBtn);

    this.pistolPlusBtn = new TextButton(
      scene,
      leftContentX + counterBtnOffset,
      counterY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.pistolQuantity < this.pistolMaxPerBatch) {
          this.pistolQuantity++;
          this.updateValues();
        }
      },
      '+',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.pistolQuantity < this.pistolMaxPerBatch) {
              this.pistolQuantity = Math.min(this.pistolMaxPerBatch, this.pistolQuantity + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.pistolPlusBtn);

    this.shieldMinusBtn = new TextButton(
      scene,
      rightContentX - counterBtnOffset,
      counterY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.shieldQuantity > DEFAULT_QUANTITY) {
          this.shieldQuantity--;
          this.updateValues();
        }
      },
      '-',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.shieldQuantity > DEFAULT_QUANTITY) {
              this.shieldQuantity = Math.max(0, this.shieldQuantity - stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.shieldMinusBtn);

    this.shieldPlusBtn = new TextButton(
      scene,
      rightContentX + counterBtnOffset,
      counterY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.shieldQuantity < this.shieldMaxPerBatch) {
          this.shieldQuantity++;
          this.updateValues();
        }
      },
      '+',
      {
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.shieldQuantity < this.shieldMaxPerBatch) {
              this.shieldQuantity = Math.min(this.shieldMaxPerBatch, this.shieldQuantity + stepSize);
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
        },
      }
    );
    this.add(this.shieldPlusBtn);

    this.pistolQuantityText = scene.add.text(leftContentX, counterY, this.pistolQuantity, largeBlackExtraBold);
    this.pistolQuantityText.setOrigin(0.5, 0.5);
    this.add(this.pistolQuantityText);

    this.shieldQuantityText = scene.add.text(rightContentX, counterY, this.shieldQuantity, largeBlackExtraBold);
    this.shieldQuantityText.setOrigin(0.5, 0.5);
    this.add(this.shieldQuantityText);

    const priceTextX = this.popup.x - 50;
    this.priceText = scene.add.text(priceTextX, priceY, '0', largeBlackExtraBold).setOrigin(0, 0.5);
    this.add(this.priceText);

    // discount
    this.alternativePrice = scene.add
      .text(priceTextX, this.priceText.y - 110, '0', largeBlackExtraBold)
      .setVisible(false);
    this.priceStrikethrough = scene.add
      .rectangle(priceTextX + 20, this.priceText.y, this.priceText.width, 5, 0x29000b)
      .setVisible(false);
    this.add(this.alternativePrice);
    this.add(this.priceStrikethrough);

    this.gasPrice = scene.add
      .text(priceTextX, priceY + 5, '+0 ETH (gas)', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(!isSimulator);
    this.add(this.gasPrice);
    this.insufficientBalance = scene.add
      .text(priceTextX, priceY + 48, 'Insufficient $GREED', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(false);
    this.add(this.insufficientBalance);

    this.coin = scene.add
      .image(this.priceText.x + this.priceText.width + 10, priceY, 'icon-coin-small')
      .setOrigin(0, 0.5)
      .setVisible(!isSimulator);
    this.add(this.coin);

    scene.game.events.on('update-gas-buy-goon', ({ gas }) => {
      if (isNaN(gas)) return;

      this.gas = gas;
      this.updateValues();
    });
    scene.game.events.on('war-upgrade-completed', () => {
      this.pistolQuantity = DEFAULT_QUANTITY;
      this.shieldQuantity = DEFAULT_QUANTITY;
      this.updateValues();
    });

    scene.game.events.on('game-ended', () => {
      this.upgradeBtn.setDisabledState(true);
    });

    scene.game.events.on('update-xtoken-balance', ({ balance }) => {
      this.xTokenBalance = balance;
      this.xgangAvailable.text = `Available: ${customFormat(balance || 0, 1)}`;
      if (this.purchaseToken === 'xGREED') this.updateValues();
    });

    scene.game.events.on(
      'update-war-upgrades',
      ({
        numberOfPistols,
        numberOfShields,
        balance,
        pistolPrice,
        shieldPrice,
        pistolMaxPerBatch,
        shieldMaxPerBatch,
      }) => {
        this.balance = balance;
        this.tokenAvailable.text = `Available: ${customFormat(balance || 0, 1)}`;
        this.pistolPrice = pistolPrice;
        this.pistolMaxPerBatch = pistolMaxPerBatch;
        this.shieldPrice = shieldPrice;
        this.shieldMaxPerBatch = shieldMaxPerBatch;
        this.numberOfPistols = numberOfPistols;
        this.numberOfShields = numberOfShields;

        this.numberOfPistolsText.text = `Pistol Upgrades: ${customFormat(numberOfPistols, 2)}`;
        this.numberOfShieldsText.text = `Shield Upgrades: ${customFormat(numberOfShields, 2)}`;
        this.updateValues();
      }
    );
    scene.game.events.on('update-referral-discount', ({ discount }) => {
      this.referralDiscount = discount;
      this.updateValues();
    });

    scene.game.events.emit('request-war-upgrades');
    scene.game.events.emit('request-gas-buy-goon');
    scene.game.events.emit('request-xtoken-balance');
  }

  onOpen() {
    this.scene.game.events.emit('request-referral-discount');
  }

  updateValues() {
    if (this.isSimulator) {
      this.priceText.text = 'FREE';
      this.priceText.x = width / 2 + 200;
      return;
    }

    const estimatedPrice = this.pistolPrice * this.pistolQuantity + this.shieldPrice * this.shieldQuantity;

    this.pistolQuantityText.text = `${this.pistolQuantity}`;
    this.shieldQuantityText.text = `${this.shieldQuantity}`;
    this.popupConfirm.updateQuantity({ pistolQuantity: this.pistolQuantity, shieldQuantity: this.shieldQuantity });
    this.popupConfirm.updateTextRight(formatter.format(estimatedPrice.toPrecision(3)));
    this.priceText.text = `${customFormat(estimatedPrice, 1)}`;
    const discountNote = ` (-${this.referralDiscount * 100}%)`;
    this.alternativePrice.text = `${customFormat(estimatedPrice * (1 - this.referralDiscount), 1)}${discountNote}`;
    this.priceStrikethrough.width = this.priceText.width;
    const formattedGas = customFormat(this.gas, 4) === '0' ? '<0.0001' : customFormat(this.gas, 4);
    this.gasPrice.text = `+${formattedGas} ETH (gas)`;
    this.gasPrice.setVisible(this.purchaseToken === 'GREED');
    this.coin.x = this.priceText.x + this.priceText.width + 10;

    const hasDifferentPrice = this.purchaseToken === 'GREED' && this.referralDiscount > 0;
    this.alternativePrice.setVisible(hasDifferentPrice);
    this.priceStrikethrough.setVisible(hasDifferentPrice);

    const balance = this.purchaseToken === 'GREED' ? this.balance : this.xTokenBalance;
    const insufficientBalance = estimatedPrice > balance;
    this.insufficientBalance.setVisible(insufficientBalance);
    this.upgradeBtn.setDisabledState(
      this.scene?.isGameEnded ||
        (!this.scene?.isUserActive && !this.isSimulator) ||
        insufficientBalance ||
        (!this.pistolQuantity && !this.shieldQuantity)
    );
  }
}

export default PopupWarUpgrades;
