import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

class PopupWelcomeNoWar extends Popup {
  constructor(scene, value, generatedNetworth) {
    super(scene, 'popup-welcome-nowar', { destroyWhenClosed: true, ribbon: 'ribbon-welcome' });

    const reputationY = this.popup.y + this.popup.height / 2 - 200;
    this.valueText = scene.add
      .text(width / 2, height / 2 + 150, `+${formatter.format(value)}`, {
        fontSize: '128px',
        color: '#fff',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.valueText.setStroke('#389d2a', 20);
    this.add(this.valueText);

    this.reputation = scene.add
      .text(width / 2, reputationY, `+${generatedNetworth.toLocaleString()}`, {
        fontSize: '72px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.reputationStar = scene.add
      .image(this.reputation.x + this.reputation.width + 30, reputationY, 'icon-star-medium')
      .setOrigin(0, 0.5);
    this.add(this.reputation);
    this.add(this.reputationStar);

    scene.game.events.on('update-claimable-status', ({ claimable, active }) => {
      if (this.buttonClaim) {
        this.remove(this.buttonClaim);
        this.buttonClaim.destroy(true);
      }
      // if (claimable && active) {
      //   this.buttonClaim = new Button(
      //     scene,
      //     width / 2,
      //     height / 2 + this.popup.height / 2 - 20,
      //     'button-claim',
      //     'button-claim-pressed',
      //     () => {
      //       if (this.loading) return;
      //       scene.footer.buttonClaim.startCoinAnimation?.();
      //       scene.game.events.emit('claim');
      //       this.close();
      //     },
      //     { sound: 'button-1' }
      //   );
      //   this.add(this.buttonClaim);
      // } else {
      this.buttonClaim = new TextButton(
        scene,
        width / 2,
        height / 2 + this.popup.height / 2 - 20,
        'button-blue',
        'button-blue-pressed',
        () => this.close(),
        'Back',
        { sound: 'close', fontSize: '82px' }
      );
      this.add(this.buttonClaim);
      // }
    });

    scene.game.events.emit('request-claimable-status');
  }
}

export default PopupWelcomeNoWar;
