import Popup from './Popup';
import TextButton from '../button/TextButton';
import Button from '../button/Button';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import { getTwitterIntentUrl } from '../../../../utils/strings';

const buttonWidth = 506;
const btnGap = 50;

const textStyle = {
  fontSize: '50px',
  fontFamily: fontFamilies.extraBold,
  color: '#29000B',
};

class PopupRewards extends Popup {
  data = null;
  template = null;

  constructor(scene) {
    super(scene, 'popup-rewards', { title: 'Rewards' });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.shareBtn = new Button(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-reward-share',
      'button-reward-share-pressed',
      () => {
        this.share();
      },
      { sound: 'button-1', disabledImage: 'button-reward-share-disabled' }
    );
    this.shareBtn.setDisabledState(true);
    this.add(this.shareBtn);

    const startingY = this.popup.y - this.popup.height / 2;
    const textX = this.popup.x + this.popup.width / 2 - 200;
    const textGap = 158;
    this.reputationText = scene.add.text(textX, startingY + 330, '---', textStyle).setOrigin(1, 0.5);
    this.raidPtsText = scene.add.text(textX, this.reputationText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.uPointText = scene.add.text(textX, this.raidPtsText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.tokenText = scene.add.text(textX, startingY + 995, '---', textStyle).setOrigin(1, 0.5);
    this.ethText = scene.add.text(textX, this.tokenText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.blastGoldText = scene.add.text(textX, this.ethText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.xUText = scene.add.text(textX, this.blastGoldText.y + textGap, '---', textStyle).setOrigin(1, 0.5);

    this.add(this.reputationText);
    this.add(this.raidPtsText);
    this.add(this.uPointText);
    this.add(this.tokenText);
    this.add(this.ethText);
    this.add(this.blastGoldText);
    this.add(this.xUText);

    scene.game.events.on('update-user-rewards', (data) => {
      const { networth, raidPoint, uPoint, ethReward, tokenReward, blastGoldReward, xUReward, completed } = data;
      this.reputationText.text = networth !== null ? formatter.format(networth) : '---';
      this.raidPtsText.text = raidPoint !== null ? formatter.format(raidPoint) : '---';
      this.uPointText.text = uPoint !== null ? formatter.format(uPoint) : '---';
      this.tokenText.text = tokenReward !== null ? formatter.format(tokenReward) : '---';
      this.ethText.text = ethReward !== null ? formatter.format(ethReward) : '---';
      this.blastGoldText.text = blastGoldReward !== null ? formatter.format(blastGoldReward) : '---';
      this.xUText.text = xUReward !== null ? formatter.format(xUReward) : '---';
      this.shareBtn.setDisabledState(!completed);
      if (completed) {
        this.data = {
          networth,
          raidPoint,
          uPoint,
          ethReward,
          tokenReward,
          blastGoldReward,
          xUReward,
        };
      }
    });

    scene.game.events.on('update-twitter-share-reward-template', ({ template }) => {
      this.template = template;
    });

    scene.game.events.emit('request-twitter-share-reward-template');
  }

  onOpen() {
    this.scene.game.events.emit('request-user-rewards');
  }

  share() {
    if (!this.template || !this.data) return;
    const text = this.template
      .replace('{ethReward}', formatter.format(this.data.ethReward))
      .replace('{tokenReward}', formatter.format(this.data.tokenReward))
      .replace('{uPoint}', formatter.format(this.data.uPoint))
      .replace('{siteUrl}', window.location.origin);
    const intentUrl = getTwitterIntentUrl({ text });
    window.open(intentUrl);
  }
}

export default PopupRewards;
