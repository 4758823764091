import Popup from './Popup';
import Button from '../button/Button';
import TextInput from '../inputs/TextInput';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat } from '../../../../utils/numbers';
import { getTwitterIntentUrl } from '../../../../utils/strings';

const { width, height } = configs;
const largeBlackExtraBold = { fontSize: fontSizes.large, color: colors.black, fontFamily: fontFamilies.extraBold };
const mediumBrownBoldCenter = {
  fontSize: fontSizes.medium,
  color: colors.brown,
  fontFamily: fontFamilies.bold,
  align: 'center',
};
const earnedTextStyle = {
  fontSize: fontSizes.medium,
  color: colors.black,
  fontFamily: fontFamilies.extraBold,
};

class PopupReferralProgram extends Popup {
  loading = false;
  code = '';
  referralDiscount = 0;
  referralProgramType = 'default';
  tweetTemplate = '';

  constructor(scene) {
    super(scene, 'popup-referral', { title: 'Referral Program' });
    const leftMargin = this.popup.x - this.popup.width / 2;
    const startingY = this.popup.y - this.popup.height / 2;
    const refCodeContainerY = startingY + 1085;
    const inviteCodeY = startingY + 450;
    const earnedTextY = refCodeContainerY + 180;
    const savedTextY = inviteCodeY + 180;
    const buttonX = width / 2 + this.popup.width * 0.35;

    this.savedText = scene.add.text(width / 2 - 50, savedTextY, `0`, earnedTextStyle);
    this.savedCoinIcon = scene.add
      .image(width / 2, this.savedText.y, 'gang-coin-small')
      .setDisplaySize(85, 85)
      .setOrigin(0, 0.15);
    this.savedTextUsd = scene.add.text(width / 2, this.savedText.y, `0`, {
      ...earnedTextStyle,
      fontFamily: fontFamilies.bold,
    });
    this.add(this.savedText);
    this.add(this.savedCoinIcon);
    this.add(this.savedTextUsd);

    this.earnedText = scene.add.text(width / 2 - 50, earnedTextY, `0`, earnedTextStyle);
    this.earnedCoinIcon = scene.add
      .image(width / 2, earnedTextY, 'gang-coin-small')
      .setDisplaySize(85, 85)
      .setOrigin(0, 0.15);
    this.earnedTextUsd = scene.add.text(width / 2, earnedTextY, `0`, {
      ...earnedTextStyle,
      fontFamily: fontFamilies.bold,
    });
    this.add(this.earnedText);
    this.add(this.earnedCoinIcon);
    this.add(this.earnedTextUsd);

    this.referralDescription = scene.add
      .text(width / 2, inviteCodeY - 180, 'Use code to get --% $GREED discount', mediumBrownBoldCenter)
      .setOrigin(0.5, 0);
    this.add(this.referralDescription);
    this.inviteCode = new TextInput(scene, width / 2, inviteCodeY, {
      placeholder: 'Enter referral code',
      textTransform: 'uppercase',
      onChange: (value) => {
        this.buttonPaste.setVisible(Boolean(!value));
        this.buttonApply.setVisible(Boolean(value));
      },
    });
    this.add(this.inviteCode);
    this.status = scene.add
      .text(width / 2, inviteCodeY + 110, '', { ...mediumBrownBoldCenter, fontSize: fontSizes.small })
      .setOrigin(0.5, 0);
    this.add(this.status);

    this.buttonPaste = new Button(
      scene,
      buttonX,
      inviteCodeY,
      'button-paste-small',
      'button-paste-small-pressed',
      async () => {
        const text = await navigator.clipboard.readText();
        this.inviteCode.updateValue(text.trim());
      }
    );
    this.buttonApply = new Button(scene, buttonX, inviteCodeY, 'button-check', 'button-check-pressed', async () => {
      this.loading = true;
      this.status.text = 'Checking code... Please wait';
      scene.game.events.emit('apply-invite-code', { code: this.inviteCode.value });
    }).setVisible(false);
    this.codeCheckIcon = scene.add.image(buttonX, inviteCodeY, 'icon-check').setOrigin(0.5, 0.5).setVisible(false);
    this.add(this.buttonPaste);
    this.add(this.buttonApply);
    this.add(this.codeCheckIcon);

    this.referTitle = scene.add
      .text(width / 2, refCodeContainerY - 280, 'Give --%, Earn --%', largeBlackExtraBold)
      .setOrigin(0.5, 0);
    this.referSubtitle = scene.add
      .text(width / 2, this.referTitle.y + 100, 'Earn --% of $GREED your referral spends', mediumBrownBoldCenter)
      .setOrigin(0.5, 0);
    this.add(this.referTitle);
    this.add(this.referSubtitle);
    this.walletContainer = scene.add.image(width / 2, refCodeContainerY, 'text-container');
    this.add(this.walletContainer);

    this.referralText = scene.add
      .text(leftMargin + this.popup.width * 0.4, refCodeContainerY, '', {
        fontSize: fontSizes.extraLarge,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.referralText);
    this.buttonCopy = new Button(
      scene,
      leftMargin + this.popup.width * 0.72,
      refCodeContainerY,
      'button-copy',
      'button-copy-pressed',
      () => navigator.clipboard.writeText(this.referralCode.toUpperCase()),
      { sound: 'button-2' }
    );
    this.add(this.buttonCopy);
    this.buttonTwitter = new Button(
      scene,
      leftMargin + this.popup.width * 0.85,
      refCodeContainerY,
      'button-twitter',
      'button-twitter-pressed',
      () => {
        const text = this.tweetTemplate
          .replace('{referralDiscount}', this.referralDiscount * 100)
          .replace('{referralCode}', this.referralCode.toUpperCase())
          .replace('{siteUrl}', window.location.origin);
        const intentUrl = getTwitterIntentUrl({ text });
        window.open(intentUrl);
      },
      { sound: 'button-2' }
    );
    this.add(this.buttonTwitter);

    const buttonBack = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue-long-thick',
      'button-blue-long-thick-pressed',
      () => {
        this.close();
      },
      'Continue game',
      { fontSize: '82px', sound: 'close' }
    );

    this.add(buttonBack);

    scene.game.events.on('update-invite-code', ({ code }) => {
      this.code = code;
      this.inviteCode.updateValue(code);
      this.disableApplyCode();
    });
    scene.game.events.on('complete-apply-invite-code', ({ status, message }) => {
      this.loading = false;
      this.status.text = message;
      if (status === 'Success') {
        this.code = this.inviteCode.value.toLowerCase();
        this.disableApplyCode();
      }
    });
    scene.game.events.on('update-referral-config', (data) => {
      this.referralConfig = data;
      this.renderReferralProgram();
    });

    scene.game.events.on('update-referral-code', ({ referralCode }) => {
      this.referralCode = referralCode;
      this.referralText.text = referralCode?.toUpperCase();
    });

    scene.game.events.on(
      'update-referral-data',
      ({
        referralTotalReward,
        referralTotalDiscount,
        referralProgramType,
        tokenPrice,
        ethPriceInUsd,
        tweetTemplate,
      }) => {
        this.referralProgramType = referralProgramType;
        this.renderReferralProgram();
        this.tweetTemplate = tweetTemplate;
        const tokenPriceInUsd = tokenPrice * ethPriceInUsd;
        const earnedInUsd = customFormat(referralTotalReward * tokenPriceInUsd, 2);
        const savedInUsd = customFormat(referralTotalDiscount * tokenPriceInUsd, 2);

        this.earnedText.text = `${customFormat(referralTotalReward, 2)}`;
        this.savedText.text = `${customFormat(referralTotalDiscount, 2)}`;
        this.earnedCoinIcon.x = this.earnedText.x + this.earnedText.width + 20;
        this.savedCoinIcon.x = this.savedText.x + this.savedText.width + 20;
        this.earnedTextUsd.text = `(~$ ${earnedInUsd})`;
        this.savedTextUsd.text = `(~$ ${savedInUsd})`;
        this.earnedTextUsd.x = this.earnedCoinIcon.x + this.earnedCoinIcon.width; // no need gap bc orginal coin size is big
        this.savedTextUsd.x = this.savedCoinIcon.x + this.savedCoinIcon.width; // no need gap bc orginal coin size is big
      }
    );
    scene.game.events.emit('request-referral-config');
    scene.game.events.emit('request-invite-code');
    scene.game.events.emit('request-referral-data');
  }

  onOpen() {
    this.scene.game.events.emit('request-referral-code');
  }

  renderReferralProgram() {
    const { referralBonus, referralDiscount } =
      this.referralConfig[this.referralProgramType] || this.referralConfig.default;
    this.referralDiscount = referralDiscount;

    this.referralDescription.text = `Use code to get ${referralDiscount * 100}% $GREED discount.`;
    this.referTitle.text = `Give ${referralDiscount * 100}%, Earn ${referralBonus * 100}%`;
    this.referSubtitle.text = `Earn ${referralBonus * 100}% of $GREED your referral spends`;
  }

  disableApplyCode() {
    this.buttonApply.setVisible(false);
    this.buttonPaste.setVisible(false);

    this.codeCheckIcon.setVisible(true);
    this.inviteCode.setDisabled(true);
    this.inviteCode.setTextStyle({ fontFamily: fontFamilies.extraBold, align: 'center' });
  }

  cleanup() {
    // reset form
    if (!this.code) this.inviteCode.updateValue('');
    this.status.text = '';
  }
}

export default PopupReferralProgram;
