import Phaser from 'phaser';

import { fontFamilies } from '../../../../utils/styles';
import configs from '../../configs/configs';

const { width } = configs;

const buttonWidth = 522;
const y = 680;
const buttonLeftX = width / 2 - buttonWidth / 2;
const buttonRightX = width / 2 + buttonWidth / 2;

const textStyle = {
  fontSize: '56px',
  fontFamily: fontFamilies.extraBold,
  color: '#FFFFFF',
};

class AuctionMode extends Phaser.GameObjects.Container {
  mode = 'xGREED';

  constructor(scene) {
    super(scene);
    this.scene = scene;

    this.container = scene.add.image(width / 2, y, 'auction-mode-container').setOrigin(0.5, 0.5);

    this.button = scene.add
      .image(buttonRightX, y, 'button-auction-mode')
      .setOrigin(0.5, 0.5)
      .setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        this.switchMode();
      });
    this.text1 = scene.add.text(buttonLeftX, y, 'xGREED', textStyle).setOrigin(0.5, 0.5);
    this.text2 = scene.add.text(buttonRightX, y, 'POOR', textStyle).setOrigin(0.5, 0.5);

    this.add(this.container);
    this.add(this.button);
    this.add(this.text1);
    this.add(this.text2);
  }

  switchMode(mode, noTriggerItems = false) {
    if (mode) {
      this.mode = mode;
    } else {
      if (this.mode === 'xGREED') {
        this.mode = 'POOR';
      } else {
        this.mode = 'xGREED';
      }
    }

    if (this.mode === 'POOR') {
      this.button.x = buttonLeftX;
      this.scene.auctionCountdown?.toggleNextPrizePool(false);
    } else {
      this.button.x = buttonRightX;
      this.scene.auctionCountdown?.toggleNextPrizePool(true);
    }

    if (!noTriggerItems) {
      this.scene.items?.showMode?.(this.mode);
    }
  }
}

export default AuctionMode;
