import Phaser from 'phaser';

import Button from '../button/Button';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import { getTwitterIntentUrl } from '../../../../utils/strings';

const { width, height } = configs;

class PopupClaimFreeThug extends Phaser.GameObjects.Container {
  value = 0;
  template = null;
  loading = false;

  constructor(scene) {
    super(scene, 0, 0);
    this.scene = scene;
    this.setVisible(false);

    this.background = scene.add.rectangle(0, 0, configs.width, configs.height, 0x260343, 0.8).setOrigin(0, 0);
    this.add(this.background);

    this.glowFx = scene.add.image(width / 2, height / 2, 'glow-fx').setOrigin(0.5, 0.5);
    this.add(this.glowFx);

    this.airdropImage = scene.add.sprite(width / 2, height / 2, 'thug-airdrop-4').setOrigin(0.5, 0.5);
    this.add(this.airdropImage);

    const amountY = this.airdropImage.y + this.airdropImage.height / 2 + 100;
    this.amountText1 = scene.add
      .text(width / 2, amountY, 'YOU HAVE CLAIMED', {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
      })
      .setOrigin(0.5, 0.5);
    this.amountText1.setStroke('#591C9A', 10);
    this.add(this.amountText1);

    const amountY2 = amountY + 100;
    this.amountText2 = scene.add
      .text(width / 2, amountY2, '-', {
        fontSize: '116px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFDD1D',
      })
      .setOrigin(0.5, 0.5);
    this.amountText2.setStroke('#591C9A', 10);
    this.add(this.amountText2);

    this.amountText3 = scene.add
      .text(width / 2, amountY2, 'Thugs', {
        fontSize: '116px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
      })
      .setOrigin(0.5, 0.5);
    this.amountText3.setStroke('#591C9A', 10);
    this.add(this.amountText3);

    const claimBtnY = amountY2 + 300;
    this.claimBtn = new Button(
      scene,
      width / 2,
      claimBtnY,
      'button-claim-thug',
      'button-claim-thug-pressed',
      () => {
        this.claim({ shared: false });
      },
      { sound: 'button-1' }
    );
    this.add(this.claimBtn);

    const shareBtnY = this.claimBtn.y + this.claimBtn.height / 2 + 130;
    this.shareBtn = new Button(
      scene,
      width / 2,
      shareBtnY,
      'button-share-x',
      'button-share-x-pressed',
      () => {
        this.claim({ shared: true });
      },
      { sound: 'button-1' }
    );
    this.add(this.shareBtn);

    scene.game.events.on('update-twitter-share-free-thugs-template', ({ template }) => {
      this.template = template;
    });

    scene.game.events.on('claim-free-thugs-done', () => {
      this.loading = false;
      this.close();
    });

    scene.game.events.on('update-number-of-free-thugs', ({ numberOfFreeThugs }) => {
      this.value = numberOfFreeThugs;
      this.amountText2.text = `${numberOfFreeThugs}`;
      this.amountText2.x =
        width / 2 - (this.amountText2.width + this.amountText3.width + 20) / 2 + this.amountText2.width / 2;
      this.amountText3.x = this.amountText2.x + this.amountText2.width / 2 + 20 + this.amountText3.width / 2;
    });

    scene.game.events.emit('request-twitter-share-free-thugs-template');
    scene.game.events.emit('request-number-of-free-thugs');
  }

  open() {
    this.setVisible(true);
    window.poof?.();
  }

  close = () => {
    this.setVisible(false);
    this.loading = false;
  };

  claim({ shared }) {
    if (!this.template || this.loading) return;
    this.loading = true;
    if (shared) {
      const text = this.template
        .replace('{value}', formatter.format(2 * this.value))
        .replace('{siteUrl}', window.location.origin);
      const intentUrl = getTwitterIntentUrl({ text });
      window.open(intentUrl);
    }
    this.scene.game.events.emit('claim-free-thugs', { shared });
  }
}

export default PopupClaimFreeThug;
